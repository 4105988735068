import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/admin/channel/page',
    method: 'get',
    params
  })
}
// delete
export function deleteChannelAPI(id) {
  return http({
    url: '/admin/channel/delete',
    method: 'post',
    data: { id }
  })
}
// add
export function addChannelAPI(data) {
  return http({
    url: '/admin/channel/add',
    method: 'post',
    data
  })
}
// edit
export function editChannelAPI(data) {
  return http({
    url: '/admin/channel/edit',
    method: 'post',
    data
  })
}
// detail
export function getChannelDetailAPI(id) {
  return http({
    url: '/admin/channel/detail',
    method: 'get',
    params: { id }
  })
}
// status
export function changeStatusAPI(data) {
  return http({
    url: '/admin/channel/status',
    method: 'post',
    data
  })
}
// 重置密码
export function resetPwAPI(data) {
  return http({
    url: '/admin/channel/editPassword',
    method: 'post',
    data
  })
}
